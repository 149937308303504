@charset "utf-8";
body {
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  background: #0f1116;
  overflow: hidden auto;
}
body.modal-open {
  padding-right: 17px !important;
}
.box {
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;
  margin-top: -111px;
  padding-top: 111px;
  padding-bottom: 50px;
}
.box section {
  width: 100%;
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
}
.box section.section-vertical {
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
}
.box section.section-vertical .container {
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  -webkit-align-self: center;
  -ms-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
@media screen and (max-width: 991px) {
  .box {
    margin-top: -74px;
    padding-top: 74px;
  }
}
header {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
footer {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
body {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin-bottom: 1em;
}
h1,
.h1 {
  font-size: 80px;
  font-weight: 500;
  letter-spacing: -0.03em;
}
h2,
.h2 {
  font-size: 56px;
  font-weight: 500;
  letter-spacing: -0.03em;
}
h3,
.h3 {
  font-size: 32px;
  font-weight: 500;
}
h4,
.h4 {
  font-size: 24px;
}
a {
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #68f7aa;
}
@media screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 40px;
  }
  h2,
  .h2 {
    font-size: 30px;
  }
  h3,
  .h3 {
    font-size: 24px;
    font-weight: 500;
  }
  h4,
  .h4 {
    font-size: 18px;
  }
}
.title-gradient {
  display: block;
  background: linear-gradient(
    -270.21deg,
    #ffffff 20%,
    rgba(255, 255, 255, 0) 108%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: relative;
  color: #ffffff;
}
.green {
  color: #68f7aa;
}
.green-variable {
  color: #09c790;
}
.purple {
  color: #4c5af5;
}
.form-group {
  margin: 0 0 30px;
  display: flex;
  flex-direction: column;
}
.form-group .form-group__wrap {
  position: relative;
  display: flex;
}
.form-group .form-group__wrap .form-control {
  padding-right: 70px;
}
.form-group .form-group__wrap [js-paste] {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #828282;
  cursor: pointer;
}
.form-group .form-group__wrap [js-paste]:hover {
  color: #9b9b9b;
}
.form-group .form-group__wrap [js-clear] {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: none;
}
.form-group .form-group__wrap [js-clear].active {
  display: block;
}
.form-group .form-group__wrap [js-clear]::before,
.form-group .form-group__wrap [js-clear]::after {
  content: "";
  display: block;
  width: 12px;
  height: 2px;
  border-radius: 3px;
  background-color: #828282;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.form-group .form-group__wrap [js-clear]::after {
  -webkit-transform: translate(3px, 5px) rotate(45deg);
  transform: translate(3px, 5px) rotate(45deg);
}
.form-group .form-group__wrap [js-clear]::before {
  -webkit-transform: translate(3px, 7px) rotate(-45deg);
  transform: translate(3px, 7px) rotate(-45deg);
}
.form-group .form-group__wrap [js-clear]:hover::before,
.form-group .form-group__wrap [js-clear]:hover::after {
  background-color: #ffffff;
}
.form-group label {
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.45);
  margin-bottom: 6px;
}
.form-group .form-control {
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  background: rgba(25, 25, 25, 0.5);
  border: 1px solid transparent;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 9px 12px;
}
.form-group .form-control:not([size]):not([multiple]):not(textarea) {
  height: 42px;
}
.form-group .form-control:hover {
  background: rgba(20, 20, 20, 0.5);
  border-color: rgba(18, 18, 18, 0);
}
.form-group .form-control:focus {
  background: rgba(20, 20, 20, 0.5);
  border-color: rgba(0, 0, 0, 0);
}
.form-group .form-control + .form-control-feedback,
.form-group .form-control + .help-block {
  border-radius: 0 0 3px 3px;
}
.form-group .form-control:placeholder {
  color: #828282;
}
.form-group textarea.form-control {
  min-height: 42px;
  padding: 9px 12px;
}
.form-group .form-control-feedback,
.form-group .help-block,
.form-group .valid-feedback,
.form-group .invalid-feedback {
  float: left;
  max-width: 100%;
  font-size: 0.725em;
  margin: 0;
  padding: 1px 5px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.45);
  background: transparent;
  border-radius: 3px;
}
.form-group .form-control-feedback:empty,
.form-group .help-block:empty,
.form-group .valid-feedback:empty,
.form-group .invalid-feedback:empty {
  display: none !important;
}
.form-group[class*="has-"],
.form-group [class*="is-"] {
  cursor: default;
}
.form-group[class*="has-"] .form-control-feedback,
.form-group [class*="is-"] .form-control-feedback,
.form-group[class*="has-"] .help-block,
.form-group [class*="is-"] .help-block,
.form-group[class*="has-"] + .valid-feedback,
.form-group [class*="is-"] + .valid-feedback,
.form-group[class*="has-"] + .invalid-feedback,
.form-group [class*="is-"] + .invalid-feedback {
  opacity: 1;
}
.form-group[class*="has-"] .form-control-feedback:empty,
.form-group [class*="is-"] .form-control-feedback:empty,
.form-group[class*="has-"] .help-block:empty,
.form-group [class*="is-"] .help-block:empty,
.form-group[class*="has-"] + .valid-feedback:empty,
.form-group [class*="is-"] + .valid-feedback:empty,
.form-group[class*="has-"] + .invalid-feedback:empty,
.form-group [class*="is-"] + .invalid-feedback:empty {
  opacity: 0;
}
.form-group.has-success .form-control,
.form-group .is-valid .form-control,
.form-group.has-success.form-control,
.form-group .is-valid.form-control {
  border-color: #28a745;
}
.form-group.has-success .form-control-feedback,
.form-group .is-valid .form-control-feedback,
.form-group.has-success .help-block,
.form-group .is-valid .help-block,
.form-group.has-success + .valid-feedback,
.form-group .is-valid + .valid-feedback {
  width: auto;
  color: #fff;
  background: #28a745;
}
.form-group.has-success
  .form-check
  input.form-check-input[type="checkbox"]
  + .form-check-label:before,
.form-group
  .is-valid
  .form-check
  input.form-check-input[type="checkbox"]
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 1px #28a745;
  box-shadow: inset 0 0 0 1px #28a745;
}
.form-group.has-success
  .form-check
  input.form-check-input[type="checkbox"]:checked
  + .form-check-label:before,
.form-group
  .is-valid
  .form-check
  input.form-check-input[type="checkbox"]:checked
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 10px #28a745;
  box-shadow: inset 0 0 0 10px #28a745;
}
.form-group.has-success
  .form-check
  input.form-check-input[type="radio"]
  + .form-check-label:before,
.form-group
  .is-valid
  .form-check
  input.form-check-input[type="radio"]
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 1px #28a745;
  box-shadow: inset 0 0 0 1px #28a745;
}
.form-group.has-success
  .form-check
  input.form-check-input[type="radio"]:checked
  + .form-check-label:before,
.form-group
  .is-valid
  .form-check
  input.form-check-input[type="radio"]:checked
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 7px #28a745;
  box-shadow: inset 0 0 0 7px #28a745;
}
.form-group.has-danger,
.form-group.has-error,
.form-group .is-invalid {
  position: relative;
}
.form-group.has-danger .form-control,
.form-group.has-error .form-control,
.form-group .is-invalid .form-control,
.form-group.has-danger.form-control,
.form-group.has-error.form-control,
.form-group .is-invalid.form-control {
  border-color: #dc3545;
}
.form-group.has-danger .invalid-feedback,
.form-group.has-error .invalid-feedback,
.form-group .is-invalid .invalid-feedback {
  display: block;
  position: absolute;
  top: calc(100% + 2px);
  color: #dc3545;
  padding-left: 15px;
}
.form-group.has-danger .form-control-feedback,
.form-group.has-error .form-control-feedback,
.form-group .is-invalid .form-control-feedback,
.form-group.has-danger .help-block,
.form-group.has-error .help-block,
.form-group .is-invalid .help-block,
.form-group.has-danger + .invalid-feedback,
.form-group.has-error + .invalid-feedback,
.form-group .is-invalid + .invalid-feedback {
  width: auto;
  color: #dc3545;
  padding-left: 15px;
}
.form-group.has-danger
  .form-check
  input.form-check-input[type="checkbox"]
  + .form-check-label:before,
.form-group.has-error
  .form-check
  input.form-check-input[type="checkbox"]
  + .form-check-label:before,
.form-group
  .is-invalid
  .form-check
  input.form-check-input[type="checkbox"]
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 1px #dc3545;
  box-shadow: inset 0 0 0 1px #dc3545;
}
.form-group.has-danger
  .form-check
  input.form-check-input[type="checkbox"]:checked
  + .form-check-label:before,
.form-group.has-error
  .form-check
  input.form-check-input[type="checkbox"]:checked
  + .form-check-label:before,
.form-group
  .is-invalid
  .form-check
  input.form-check-input[type="checkbox"]:checked
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 10px #dc3545;
  box-shadow: inset 0 0 0 10px #dc3545;
}
.form-group.has-danger
  .form-check
  input.form-check-input[type="radio"]
  + .form-check-label:before,
.form-group.has-error
  .form-check
  input.form-check-input[type="radio"]
  + .form-check-label:before,
.form-group
  .is-invalid
  .form-check
  input.form-check-input[type="radio"]
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 1px #dc3545;
  box-shadow: inset 0 0 0 1px #dc3545;
}
.form-group.has-danger
  .form-check
  input.form-check-input[type="radio"]:checked
  + .form-check-label:before,
.form-group.has-error
  .form-check
  input.form-check-input[type="radio"]:checked
  + .form-check-label:before,
.form-group
  .is-invalid
  .form-check
  input.form-check-input[type="radio"]:checked
  + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 7px #dc3545;
  box-shadow: inset 0 0 0 7px #dc3545;
}
.content {
  position: relative;
}
.content .main-block__bg-left {
  top: 20vh;
}
.content .main-block__bg-right {
  top: 120vh;
}
.content p + h1,
.content p + h2,
.content p + h3,
.content ul + h1,
.content ul + h2,
.content ul + h3,
.content ol + h1,
.content ol + h2,
.content ol + h3 {
  margin-top: 40px;
}
.content h1,
.content .h1 {
  font-size: 56px;
  font-weight: 500;
  letter-spacing: -0.03em;
  margin-bottom: 20px;
}
.content h2,
.content .h2 {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.03em;
  margin-bottom: 20px;
}
.content h3,
.content .h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
}
.content h4,
.content .h4 {
  font-size: 18px;
}
@media screen and (max-width: 991px) {
  .content {
    padding-top: 30px;
  }
  .content h1,
  .content .h1 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .content h2,
  .content .h2 {
    font-size: 24px;
  }
  .content h3,
  .content .h3 {
    font-size: 18px;
  }
  .content h4,
  .content .h4 {
    font-size: 16px;
  }
}
.content a {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #17a2b8;
}
.content a:hover {
  color: #0c525d;
}
.content ul,
.content ol {
  list-style: none;
  padding: 0;
}
.content ul li + li,
.content ol li + li,
.content ul li > ul,
.content ol li > ul,
.content ul li > ol,
.content ol li > ol {
  margin-top: 20px;
}
.content ul li {
  padding-left: 20px;
  position: relative;
}
.content ul li:before {
  border-radius: 50%;
  background: #11a97d;
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  top: 0.5em;
  left: 0;
}
.content ol {
  counter-set: list;
  counter-reset: list;
}
.content ol li {
  counter-increment: list;
}
.content ol li:before {
  content: counter(list) ". ";
  color: #dc3545;
}
.icon:before {
  vertical-align: middle;
}
.modal .modal-header {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  border: 0;
}
.modal .modal-header .close {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  text-align: center;
  outline: none;
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.modal .modal-header .close:after,
.modal .modal-header .close:before {
  content: "";
  display: block;
  margin: 0 -2px;
  height: 2px;
  background: #555;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.modal .modal-header .close:after {
  margin-top: -2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal .modal-header .close:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modal .modal-header .close:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.modal .modal-footer {
  display: block;
  border: 0;
}
.modal .modal-footer .buttons-group {
  margin-top: 0;
}
.maintains {
  text-align: center;
}
.maintains svg {
  width: 600px;
  max-width: 100%;
  margin-bottom: 2em;
}
.maintains svg .maintainse_bg {
  fill: #0f1116;
}
.maintains svg .maintainse_code_bg {
  fill: #ffffff;
}
.maintains svg .maintainse_border {
  stroke: #ffffff;
  fill: #0f1116;
}
@media (max-width: 991px) {
  .maintains {
    max-height: 100vh;
    font-size: 2.5vh;
  }
  .maintains h1 {
    margin-bottom: 2vh;
    font-size: 4vh;
  }
  .maintains .desc {
    font-size: 2vh;
  }
  .maintains svg {
    max-height: 30vh;
    width: auto;
  }
}
.btn {
  padding: 15px 27px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .btn {
    font-size: 16px;
  }
}
.btn.btn-primary {
  color: #fff;
  border-color: #11a97d;
  background-color: #11a97d;
  cursor: pointer;
  position: relative;
  padding: 1px;
}
.btn.btn-primary:hover,
.btn.btn-primary:active:not(:disabled):not(.disabled) {
  border-color: #0c7b5b;
  background-color: #0c7b5b;
}
.btn.btn-primary:focus {
  border-color: #11a97d;
  background-color: #11a97d;
}
.btn.btn-primary.disabled,
.btn.btn-primary:disabled {
  color: #fff;
  border-color: #11a97d;
  background-color: #11a97d;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: not-allowed;
}
.btn.btn-primary span {
  position: relative;
  z-index: 1;
  background-color: #11a97d;
  text-align: center;
  display: block;
  padding: 15px 27px;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-primary:hover span,
.btn.btn-primary:active:not(:disabled):not(.disabled) span {
  border-color: #0f926c;
  background-color: #0f926c;
}
.btn.btn-primary:before {
  content: "";
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(81, 100, 154, 0)),
    to(#68f7aa)
  );
  background: linear-gradient(to left, rgba(81, 100, 154, 0), #68f7aa);
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 8px;
}
.btn.btn-purple {
  color: #fff;
  border-color: #101217;
  background-color: #101217;
  cursor: pointer;
  position: relative;
  padding: 1px;
  font-weight: 400;
  border-radius: 12px;
}
.btn.btn-purple:hover,
.btn.btn-purple:active:not(:disabled):not(.disabled) {
  border-color: #000000;
  background-color: #000000;
}
.btn.btn-purple:focus {
  border-color: #101217;
  background-color: #101217;
}
.btn.btn-purple.disabled,
.btn.btn-purple:disabled {
  color: #fff;
  border-color: #101217;
  background-color: #101217;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-purple span {
  position: relative;
  z-index: 1;
  background-color: #101217;
  text-align: center;
  display: block;
  padding: 15px 27px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-purple:hover span,
.btn.btn-purple:active:not(:disabled):not(.disabled) span {
  border-color: #060608;
  background-color: #060608;
}
.btn.btn-purple:before {
  content: "";
  background: linear-gradient(135deg, #7d8afe, #5c6192);
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 12px;
}
.btn.btn-custom {
  color: #fff;
  border-color: #101217;
  background-color: #101217;
  cursor: pointer;
  position: relative;
  padding: 1px;
  font-weight: 400;
  border-radius: 8px;
}
.btn.btn-custom:hover,
.btn.btn-custom:active:not(:disabled):not(.disabled) {
  border-color: #000000;
  background-color: #000000;
}
.btn.btn-custom:focus {
  border-color: #101217;
  background-color: #101217;
}
.btn.btn-custom.disabled,
.btn.btn-custom:disabled {
  color: #fff;
  border-color: #101217;
  background-color: #101217;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-custom span {
  position: relative;
  z-index: 1;
  background: linear-gradient(197deg, #151742 -20%, #101217 100%);
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 26px 80px 27px 24px;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 100%;
}
.btn.btn-custom span b {
  display: block;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 15px;
}
.btn.btn-custom span p {
  display: block;
  font-size: 16px;
  margin: 0;
}
.btn.btn-custom .btn-custom__arrow {
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  z-index: 1;
}
.btn.btn-custom .btn-custom__arrow img {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
}
.btn.btn-custom .btn-custom__arrow img:last-of-type {
  opacity: 0;
}
.btn.btn-custom .btn-custom__arrow img:first-of-type {
  opacity: 1;
}
.btn.btn-custom:before {
  content: "";
  background: linear-gradient(15deg, #1d1e2c, #353f6f);
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 16px;
}
.btn.btn-custom:hover span,
.btn.btn-custom:active:not(:disabled):not(.disabled) span {
  background: linear-gradient(70deg, #113e35, #101319);
}
.btn.btn-custom:hover:before,
.btn.btn-custom:active:not(:disabled):not(.disabled):before {
  background: linear-gradient(135deg, #126655, #102d2b);
}
.btn.btn-custom:hover .btn-custom__arrow img:last-of-type,
.btn.btn-custom:active:not(:disabled):not(.disabled)
  .btn-custom__arrow
  img:last-of-type {
  opacity: 1;
}
.btn.btn-custom:hover .btn-custom__arrow img:first-of-type,
.btn.btn-custom:active:not(:disabled):not(.disabled)
  .btn-custom__arrow
  img:first-of-type {
  opacity: 0;
}
.btn.btn-app {
  color: #fff;
  border-color: #101217;
  background-color: #101217;
  cursor: pointer;
  position: relative;
  padding: 1px;
  font-weight: 400;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn-app:hover,
.btn.btn-app:active:not(:disabled):not(.disabled) {
  border-color: #000000;
  background-color: #000000;
}
.btn.btn-app:focus {
  border-color: #101217;
  background-color: #101217;
}
.btn.btn-app.disabled,
.btn.btn-app:disabled {
  color: #fff;
  border-color: #101217;
  background-color: #101217;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: not-allowed ;
}
.btn.btn-app span {
  position: relative;
  z-index: 1;
  background: linear-gradient(120deg, #11a97d 10%, #552ed3 100%);
  text-align: center;
  display: block;
  padding: 12px 16px;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 100%;
  /* border-radius: 50px; */
}
.btn.btn-app span b {
  display: block;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 15px;
}
.btn.btn-app span p {
  display: block;
  font-size: 16px;
  margin: 0;
}
.btn.btn-app:before {
  content: "";
  background: linear-gradient(172deg, #12293c, #174f75);
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 8px;
}
.btn.btn-app:hover span,
.btn.btn-app:active:not(:disabled):not(.disabled) span {
  background: linear-gradient(300deg, #11a97d 10%, #552ed3 100%);
}
.btn.btn-app.btn-app_custom span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  padding: 16px 0 15px;
}
.btn.btn-success {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
  cursor: pointer;
}
.btn.btn-success:hover,
.btn.btn-success:active:not(:disabled):not(.disabled) {
  border-color: #1e7e34;
  background-color: #1e7e34;
}
.btn.btn-success:focus {
  border-color: #28a745;
  background-color: #28a745;
}
.btn.btn-success.disabled,
.btn.btn-success:disabled {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-info {
  color: #fff;
  border-color: #17a2b8;
  background-color: #17a2b8;
  cursor: pointer;
}
.btn.btn-info:hover,
.btn.btn-info:active:not(:disabled):not(.disabled) {
  border-color: #117a8b;
  background-color: #117a8b;
}
.btn.btn-info:focus {
  border-color: #17a2b8;
  background-color: #17a2b8;
}
.btn.btn-info.disabled,
.btn.btn-info:disabled {
  color: #fff;
  border-color: #17a2b8;
  background-color: #17a2b8;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-warning {
  color: #fff;
  border-color: #ffc107;
  background-color: #ffc107;
  cursor: pointer;
}
.btn.btn-warning:hover,
.btn.btn-warning:active:not(:disabled):not(.disabled) {
  border-color: #d39e00;
  background-color: #d39e00;
}
.btn.btn-warning:focus {
  border-color: #ffc107;
  background-color: #ffc107;
}
.btn.btn-warning.disabled,
.btn.btn-warning:disabled {
  color: #fff;
  border-color: #ffc107;
  background-color: #ffc107;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-danger {
  color: #fff;
  border-color: #dc3545;
  background-color: #dc3545;
  cursor: pointer;
}
.btn.btn-danger:hover,
.btn.btn-danger:active:not(:disabled):not(.disabled) {
  border-color: #bd2130;
  background-color: #bd2130;
}
.btn.btn-danger:focus {
  border-color: #dc3545;
  background-color: #dc3545;
}
.btn.btn-danger.disabled,
.btn.btn-danger:disabled {
  color: #fff;
  border-color: #dc3545;
  background-color: #dc3545;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
  cursor: pointer;
}
.btn.btn-outline-white:hover,
.btn.btn-outline-white:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn.btn-outline-white:focus {
  border-color: #ffffff;
}
.btn.btn-outline-white.disabled,
.btn.btn-outline-white:disabled {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-white:hover,
.btn.btn-outline-white:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #11a97d;
  border-color: #11a97d;
}
.btn.btn-outline-white:focus {
  border-color: #11a97d;
}
.btn.btn-outline-primary {
  color: #11a97d;
  background-color: transparent;
  border-color: #11a97d;
  cursor: pointer;
}
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #11a97d;
  border-color: #11a97d;
}
.btn.btn-outline-primary:focus {
  border-color: #11a97d;
}
.btn.btn-outline-primary.disabled,
.btn.btn-outline-primary:disabled {
  color: #11a97d;
  background-color: transparent;
  border-color: #11a97d;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  border-color: #28a745;
  cursor: pointer;
}
.btn.btn-outline-success:hover,
.btn.btn-outline-success:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn.btn-outline-success:focus {
  border-color: #28a745;
}
.btn.btn-outline-success.disabled,
.btn.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
  border-color: #28a745;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  border-color: #17a2b8;
  cursor: pointer;
}
.btn.btn-outline-info:hover,
.btn.btn-outline-info:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn.btn-outline-info:focus {
  border-color: #17a2b8;
}
.btn.btn-outline-info.disabled,
.btn.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
  border-color: #17a2b8;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  border-color: #ffc107;
  cursor: pointer;
}
.btn.btn-outline-warning:hover,
.btn.btn-outline-warning:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn.btn-outline-warning:focus {
  border-color: #ffc107;
}
.btn.btn-outline-warning.disabled,
.btn.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
  border-color: #ffc107;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  border-color: #dc3545;
  cursor: pointer;
}
.btn.btn-outline-danger:hover,
.btn.btn-outline-danger:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn.btn-outline-danger:focus {
  border-color: #dc3545;
}
.btn.btn-outline-danger.disabled,
.btn.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
  border-color: #dc3545;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.footer {
  position: relative;
  overflow: hidden;
  padding: 64px 0 30px;
  background-color: #020204;
}
.footer b {
  color: #11a97d;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.04em;
  margin: 0 0 16px;
  display: block;
}
.footer a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
}
.footer a img {
  width: 18px;
  height: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 15px;
}
.footer a + a {
  margin: 16px auto 0 0;
}
.footer__decor {
  position: absolute;
  left: 67%;
  top: 70px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0.51;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}
.footer__copy {
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  margin: 36px 0 0;
}
.footer-custom {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.footer-custom a + a {
  margin-left: 20px;
}
.main-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0;
  height: calc(100vh - 111px);
  min-height: 650px;
  position: relative;
}
.main-block .container {
  position: relative;
  z-index: 1;
  margin: auto auto 3%;
}
.main-block h1 {
  margin: 0 0 34px;
  line-height: 1;
}
.main-block h4 {
  margin: 0;
  font-weight: 400;
}
.main-block__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 56px;
}
.main-block__btns .btn + .btn {
  margin-left: 27px;
}
.main-block__btns_custom .btn {
  min-width: 240px;
}
.main-block__loop {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-20%, -63%);
  transform: translate(-20%, -63%);
  z-index: -1;
  pointer-events: none;
}
.main-block__loop video {
  width: auto;
  height: 100vh;
  min-height: 500px;
}
.main-block__loop::after {
  content: "";
  display: block;
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #0f1116;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main-block__loop svg {
  width: auto;
  height: 100vh;
  min-height: 500px;
}
.main-block__bg-left {
  position: absolute;
  right: 50%;
  top: 40%;
  margin-right: -100px;
  z-index: -1;
}
.main-block__bg-right {
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translateX(250px);
  transform: translateX(250px);
  z-index: -1;
}
.main-block__card {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(7%, -330px);
  transform: translate(7%, -330px);
  z-index: 1;
}
.main-block__card img {
  width: 572px;
}
.main-block_custom .container {
  margin: auto auto 5%;
  z-index: 2;
}
.main-block_custom h4 {
  max-width: 530px;
}
.main-block_custom .main-block__loop img {
  height: 90vh;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-48%, -48%);
  transform: translate(-48%, -48%);
}
.main-block_custom .main-block__loop svg,
.main-block_custom .main-block__loop video {
  opacity: 0.2;
}
.main-block_custom .main-block__loop:after {
  display: none;
}
.main-block_custom .main-block__btns {
  margin-top: 50px;
}
@-webkit-keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.75);
    transform: rotate(180deg) scale(0.75);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
@keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.75);
    transform: rotate(180deg) scale(0.75);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate3 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  10% {
    -webkit-transform: rotate(0deg) scale(0.8);
    transform: rotate(0deg) scale(0.8);
  }
  30% {
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
  40% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
@keyframes rotate3 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  10% {
    -webkit-transform: rotate(0deg) scale(0.8);
    transform: rotate(0deg) scale(0.8);
  }
  30% {
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
  40% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
.wh1 {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: rotate3 8s infinite ease-in-out;
  animation: rotate3 8s infinite ease-in-out;
}
.wh2 {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  animation: rotate3 8s infinite ease-in-out reverse;
}
@-webkit-keyframes opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.wh-dots-1 g {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.wh-dots-1 g:nth-of-type(1) {
  -webkit-animation: opacity 7s infinite ease-in-out;
  animation: opacity 7s infinite ease-in-out;
}
.wh-dots-1 g:nth-of-type(2) {
  -webkit-animation: opacity 7s infinite ease-in-out 0.5s;
  animation: opacity 7s infinite ease-in-out 0.5s;
}
.wh-dots-1 g:nth-of-type(3) {
  -webkit-animation: opacity 7s infinite ease-in-out 1.5s;
  animation: opacity 7s infinite ease-in-out 1.5s;
}
.wh-dots-2 g {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.wh-dots-2 g:nth-of-type(1) {
  -webkit-animation: opacity 7s infinite ease-in-out 1.5s;
  animation: opacity 7s infinite ease-in-out 1.5s;
}
.wh-dots-2 g:nth-of-type(2) {
  -webkit-animation: opacity 7s infinite ease-in-out 2s;
  animation: opacity 7s infinite ease-in-out 2s;
}
.wh-dots-2 g:nth-of-type(3) {
  -webkit-animation: opacity 7s infinite ease-in-out 2.5s;
  animation: opacity 7s infinite ease-in-out 2.5s;
}
@-webkit-keyframes transform1 {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  20% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  35% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  65% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  80% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
}
@keyframes transform1 {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  20% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  35% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  65% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  80% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
}
.wh-rect-1 {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: transform1 8s infinite ease-in-out;
  animation: transform1 8s infinite ease-in-out;
}
.wh-rect-2 {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: transform1 8s infinite ease-in-out 3s;
  animation: transform1 8s infinite ease-in-out 3s;
}
.wh-rect-3 {
  transform-box: fill-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: transform1 8s infinite ease-in-out 5s;
  animation: transform1 8s infinite ease-in-out 5s;
}
section.detail-line {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.detail-line {
  background: linear-gradient(89.09deg, #11a97d 34.02%, #2e01f8 156.05%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-top: 3px solid;
  border-bottom: 3px solid;
  -o-border-image: linear-gradient(
    to right,
    rgba(81, 100, 154, 0.5),
    rgba(73, 72, 114, 0.2)
  );
  border-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(81, 100, 154, 0.5)),
    to(rgba(73, 72, 114, 0.2))
  );
  border-image: linear-gradient(
    to right,
    rgba(81, 100, 154, 0.5),
    rgba(73, 72, 114, 0.2)
  );
  border-image-slice: 1;
  margin: 120px 0 0;
  position: relative;
}
.detail-line__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  max-width: 1920px;
  margin: 0 auto;
}
.detail-line__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 33px 10px;
  color: #ffffff;
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  white-space: nowrap;
}
.detail-line__item p {
  font-size: 16px;
  margin: 0 16px 0 0;
}
.detail-line__item b {
  font-size: 48px;
  line-height: 1;
  font-weight: 600;
}
.detail-line__item + .detail-line__item {
  border-left: 2px solid #68f7aa;
}
@media screen and (max-width: 1650px) {
  .detail-line__item b {
    font-size: 32px;
  }
}
@media screen and (max-width: 1500px) {
  .detail-line__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .detail-line__item b {
    margin: 0 0 10px;
  }
  .detail-line__item p {
    margin: 0;
  }
}
.init-steps {
  padding: 200px 0 20px;
}
.init-steps h2 {
  margin: 0 0 100px;
}
.init-steps h3 {
  max-width: 200px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.43;
  color: #ffffff;
  margin: 0 0 56px;
}
.init-steps p {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 52px;
}
.init-steps .btn {
  width: 100%;
  margin: auto 0 0;
}
.init-steps .btn span {
  padding: 15px 5px;
}
.init-steps__list {
  margin: 0 -27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.init-steps__item {
  width: calc(100% / 3 - 54px);
  margin: 0 27px;
  padding: 44px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.init-steps__item > * {
  position: relative;
  z-index: 2;
}
.init-steps__item:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 3px;
  border-radius: 14px;
  background: linear-gradient(197deg, #151742 -20%, #101217 100%);
}
.init-steps__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background: linear-gradient(
    -45deg,
    rgba(81, 100, 154, 0.6) 80%,
    #68f7aa 100%
  );
}
.init-steps__img {
  height: 220px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.init-steps__img img {
  max-width: 100%;
}
.init-steps__numb {
  position: absolute;
  right: 36px;
  height: 36px;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}
.ecosystem {
  position: relative;
}
.ecosystem h2 {
  line-height: 1;
  margin: 0 0 50px;
}
.ecosystem__btn-wrap {
  margin: 34px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ecosystem__main {
  text-align: right;
  position: relative;
  padding: 260px 0 210px 0;
}
.ecosystem__main h2,
.ecosystem__main h4 {
  position: relative;
}
.ecosystem__decor-bottom {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
}
.ecosystem__decor-bottom svg {
  width: 100%;
}
.ecosystem__decor {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-200px, -50%);
  transform: translate(-200px, -50%);
}
.ecosystem__decor .anim-eco path {
  -webkit-transform-origin: 39% 47.3%;
  transform-origin: 39% 47.3%;
}
.ecosystem__decor .anim-eco path:nth-of-type(odd) {
  animation: rotate 5s infinite linear reverse;
}
.ecosystem__decor .anim-eco path:nth-of-type(even) {
  -webkit-animation: rotate 7s infinite linear;
  animation: rotate 7s infinite linear;
}
.ecosystem__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 -27px;
}
.ecosystem__item {
  width: calc(100% / 3 - 54px);
  margin: 0 27px 22px;
}
.what-block {
  padding: 200px 0 0;
  position: relative;
}
.what-block .container {
  position: relative;
}
.what-block h2 {
  margin: 0 0 130px;
}
.what-block__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.what-block__main {
  max-width: 624px;
  margin-right: auto;
  padding-top: 20px;
}
.what-block__main p {
  font-size: 24px;
  line-height: 1.5;
  margin: 0 0 40px;
}
.what-block__main .btn {
  margin: 60px 0 0;
}
.what-block__side {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 30px;
}
.what-block__side img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.what-block__side svg {
  width: 392px;
  position: relative;
}
.what-block__bg {
  position: absolute;
  left: 50%;
  bottom: -4%;
  z-index: 0;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.what-block__bg svg {
  width: 2178px;
  height: auto;
}
.what-block__list {
  margin: 140px -14px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.what-block__item {
  width: calc(150% - 28px);
  margin: 0 14px;
  padding: 44px 44px 32px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.what-block__item .btn {
  width: auto;
  margin-right: auto;
}
.what-block__item .btn span {
  background-color: #101a1f;
}
.what-block__item h3 {
  margin: 0 0 26px;
}
.what-block__item p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 25px;
}
.what-block__item > * {
  position: relative;
  z-index: 2;
}
.what-block__item:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 3px;
  border-radius: 14px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#101a1f),
    to(#16285b)
  );
  background: linear-gradient(to top, #101a1f, #16285b);
}
.what-block__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background: linear-gradient(
    -45deg,
    rgba(81, 100, 154, 0.6) 80%,
    rgba(104, 247, 170, 0.7) 100%
  );
}
.what-block__item:last-of-type.what-block__item:after {
  background: linear-gradient(
    135deg,
    rgba(81, 100, 154, 0.6) 80%,
    rgba(104, 247, 170, 0.7) 100%
  );
}
.what-block__img {
  height: 128px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 26px;
}
.what-block__img img {
  max-height: 100%;
  width: auto;
  z-index: 1;
  position: relative;
}
.what-block__img-decor {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.what-block__social {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 7px;
}
.what-block__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: relative;
}
.what-block__social a + a {
  margin-left: 24px;
}
.what-block__social a svg {
  width: 23px;
  height: auto;
  position: relative;
  z-index: 2;
}
.what-block__social a:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 3px;
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#101a1f),
    to(#16285b)
  );
  background: linear-gradient(to top, #101a1f, #16285b);
}
.what-block__social a:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: linear-gradient(
    -45deg,
    rgba(81, 100, 154, 0.6) 48%,
    rgba(104, 247, 170, 0.7) 100%
  );
}
.what-block__social a:hover:before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(16, 26, 31, 0.7)),
    to(rgba(22, 40, 91, 0.7))
  );
  background: linear-gradient(
    to top,
    rgba(16, 26, 31, 0.7),
    rgba(22, 40, 91, 0.7)
  );
}
.build {
  padding: 160px 0 120px;
  position: relative;
}
.build h2 {
  margin: 0 0 130px;
  width: auto;
  display: inline-block;
}
.build__title-decor {
  left: -100px;
  top: -100px;
  position: absolute;
  z-index: -1;
}
.build__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.build__main {
  max-width: 529px;
  margin-right: auto;
  position: relative;
}
.build__main p {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 40px;
}
.build__main .btn {
  margin: 70px 0 0;
}
.build__side {
  position: relative;
  max-width: 550px;
  margin-left: 30px;
}
.build__side > img {
  width: 100%;
  height: auto;
}
.build__btn-decor {
  position: absolute;
  left: -140px;
  bottom: -120px;
  pointer-events: none;
  z-index: -1;
}
.build__right-decor {
  position: absolute;
  left: -80px;
  top: -150px;
  pointer-events: none;
  z-index: 1;
}
.build__right-decor img {
  width: 1300px;
  height: auto;
}
.build__mini-decor {
  position: absolute;
  right: 28%;
  top: 20%;
  pointer-events: none;
  z-index: -1;
}
.build__line-decor {
  position: absolute;
  left: 0;
  top: 0;
}
.mobile-apps {
  padding: 160px 0 250px;
}
.mobile-apps__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mobile-apps__img {
  width: 381px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 110px;
}
.mobile-apps__img img {
  width: 100%;
  height: auto;
}
.mobile-apps__main {
  padding-bottom: 70px;
}
.mobile-apps__main > img {
  display: none;
}
.mobile-apps h2 {
  display: inline-block;
  margin: 0 0 35px;
}
.mobile-apps p {
  font-size: 24px;
  line-height: 1.5;
  margin: 0 0 20px;
  max-width: 624px;
}
.mobile-apps__btns {
  margin: 70px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 500px;
  position: relative;
}
.mobile-apps__btns .btn {
  min-width: 216px;
  margin: 0 32px 32px 0;
}
.mobile-apps__btns .btn img {
  height: 40px;
  width: auto;
}
.mobile-apps__decor {
  position: absolute;
  left: -130px;
  top: -135px;
}
.browser {
  padding: 0 0 250px;
  position: relative;
}
.browser__decor {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
.browser h2 {
  margin: 0 0 70px;
}
.browser p {
  font-size: 24px;
  line-height: 1.5;
  margin: 0 0 40px;
}
.browser .btn {
  min-width: 228px;
}
.browser__main {
  max-width: 562px;
  position: relative;
}
.browser__app {
  margin: 0 0 51px;
  display: block;
  max-width: 100%;
}
.browser__book {
  width: 750px;
  position: absolute;
  left: 95%;
  top: 3.5%;
  z-index: -1;
}
.eco-card {
  position: relative;
  padding: 50px 70px 40px;
}
.eco-card h3 {
  display: inline-block;
}
.eco-card > * {
  position: relative;
  z-index: 1;
}
.eco-card:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 14px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#10131b),
    to(#151748)
  );
  background: linear-gradient(0deg, #10131b, #151748);
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 0;
}
.eco-card:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#1b1d2b),
    to(#364170)
  );
  background: linear-gradient(0deg, #1b1d2b, #364170);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.explore-ecosystem {
  padding: 80px 0 180px;
  position: relative;
}
.explore-ecosystem h1 {
  margin: 0 0 84px;
}
.explore-ecosystem__ex-decor {
  position: absolute;
  left: 50%;
  top: -70px;
  -webkit-transform: translateX(-110%);
  transform: translateX(-110%);
  z-index: -1;
}
.explore-ecosystem__ex-decor2 {
  position: absolute;
  left: 50%;
  top: -50px;
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
  z-index: -1;
}
.explore-ecosystem__ex-decor3 {
  position: absolute;
  left: 50%;
  top: 25%;
  -webkit-transform: translateX(-110%);
  transform: translateX(-110%);
  z-index: -1;
}
.explore-ecosystem__ex-decor5 {
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%, -35%);
  transform: translate(-50%, -35%);
  z-index: -1;
}
.ecosystem-apps h3 {
  margin: 0 0 50px;
}
.ecosystem-apps__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -39px;
}
.ecosystem-apps__item {
  width: calc(50% - 11px);
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  padding: 50px 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.ecosystem-apps__item:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 14px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#10131b),
    to(#151748)
  );
  background: linear-gradient(0deg, #10131b, #151748);
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 0;
}
.ecosystem-apps__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4f9390),
    to(#3b5386)
  );
  background: linear-gradient(180deg, #4f9390, #3b5386);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.ecosystem-apps__main {
  width: 44.5%;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ecosystem-apps__main h4 {
  letter-spacing: -0.03em;
  line-height: 1.5;
  margin: 0 0 35px;
  position: relative;
  z-index: 2;
}
.ecosystem-apps__main .btn {
  font-weight: 700;
}
.ecosystem-apps__main .btn span {
  padding: 9px 27px;
}
.ecosystem-apps__mobile {
  width: 210px;
  position: absolute;
  left: 38px;
  bottom: 3px;
}
.ecosystem-apps__app-decor {
  position: absolute;
  left: -230px;
  top: -170px;
}
.ecosystem-apps__card-decor {
  position: absolute;
  left: -100px;
  top: -30px;
}
.ecosystem-apps__desktop {
  width: 185px;
  position: absolute;
  left: 3px;
  bottom: 3px;
  border-radius: 0 0 0 14px;
}
.ecosystem-apps__desc-decor {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}
.blockchain {
  margin: 22px 0 0;
}
.blockchain > h3 {
  margin: 0 0 60px;
}
.blockchain__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -30px;
}
.blockchain__nav-decor {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-55%, -55%);
  transform: translate(-55%, -55%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blockchain__item {
  width: calc(20% - 60px);
  height: 148px;
  margin: 0 30px;
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.blockchain__item:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 14px;
  background: #111325;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blockchain__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4f9390),
    to(#3b5386)
  );
  background: linear-gradient(180deg, #4f9390, #3b5386);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.blockchain__item:hover::before {
  background: #14172c;
}
.blockchain__item.active::before {
  background: linear-gradient(2deg, #18184f 18%, #11a97d 130%);
}
.blockchain__item.active .blockchain__nav-decor {
  opacity: 0.8;
}
.blockchain__item span {
  position: relative;
  z-index: 2;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blockchain__item span > img {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 5px;
}
.blockchain__item span .absolute {
  position: absolute;
  left: 50%;
  bottom: 100%;
  -webkit-transform: translate(-50%, -7px);
  transform: translate(-50%, -7px);
}
.blockchain__content {
  margin: 48px 0 0;
  padding-top: 45px;
  border-top: 1px solid #374469;
}
.blockchain__plane {
  position: relative;
}
.blockchain__plane-decor {
  position: absolute;
  right: -150px;
  bottom: -130px;
}
.blockchain__plane p {
  max-width: 624px;
  margin: 0 40px 0 0;
  font-size: 24px;
  line-height: 1.5;
}
.blockchain__plane p + p {
  margin-top: 15px;
}
.blockchain__plane .btn {
  width: 148px;
  font-weight: 700;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: auto 0 0 auto;
}
.blockchain__plane .btn span {
  padding: 9px 25px;
}
.blockchain__plane-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blockchain .w3w {
  width: 26px;
  height: auto;
  margin: -2px 5px 0 0;
}
.develop {
  margin: 22px 0 0;
}
.develop h3 {
  margin: 0 0 60px;
}
.develop__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.develop__item {
  width: calc((100% - 92px) / 6);
}
.develop__item:last-of-type {
  width: 92px;
  text-align: right;
}
.develop__img {
  height: 88px;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.develop__img img {
  height: 100%;
  width: auto;
}
.develop p {
  margin: 40px 0 0;
  padding-top: 28px;
  border-top: 1px solid #374469;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.info-desc {
  display: -ms-grid;
  display: grid;
  grid-gap: 16px;
  -ms-grid-columns: calc(28.3% - 16px) 16px calc(22.4% - 16px) 16px
    calc(22.4% - 16px) 16px calc(28.3% - 16px);
  grid-template-columns:
    calc(28.3% - 16px) calc(22.4% - 16px) calc(22.4% - 16px)
    calc(28.3% - 16px);
  -ms-grid-rows: auto 16px auto 16px auto 16px auto;
  grid-template-rows: repeat(4, auto);
  margin: 0 0 180px;
  position: relative;
}
.info-desc > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.info-desc > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.info-desc > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.info-desc > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.info-desc > *:nth-child(5) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}
.info-desc > *:nth-child(6) {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}
.info-desc > *:nth-child(7) {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
}
.info-desc > *:nth-child(8) {
  -ms-grid-row: 3;
  -ms-grid-column: 7;
}
.info-desc > *:nth-child(9) {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}
.info-desc > *:nth-child(10) {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
}
.info-desc > *:nth-child(11) {
  -ms-grid-row: 5;
  -ms-grid-column: 5;
}
.info-desc > *:nth-child(12) {
  -ms-grid-row: 5;
  -ms-grid-column: 7;
}
.info-desc > *:nth-child(13) {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
}
.info-desc > *:nth-child(14) {
  -ms-grid-row: 7;
  -ms-grid-column: 3;
}
.info-desc > *:nth-child(15) {
  -ms-grid-row: 7;
  -ms-grid-column: 5;
}
.info-desc > *:nth-child(16) {
  -ms-grid-row: 7;
  -ms-grid-column: 7;
}
.info-desc__bg {
  position: absolute;
  left: 50%;
  top: 70%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.info-desc__wrap {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.info-desc__item {
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px;
}
.info-desc__item > * {
  position: relative;
  z-index: 4;
}
.info-desc__item .logo,
.info-desc__item p,
.info-desc__item span {
  z-index: 4;
}
.info-desc__item .bg {
  z-index: 2;
}
.info-desc__item .shadow {
  z-index: 3;
}
.info-desc__item:before {
  content: "";
  display: block;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 3px;
  border-radius: 14px;
  background: #0f1116;
}
.info-desc__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background: linear-gradient(
    -45deg,
    rgba(81, 100, 154, 0.6) 80%,
    rgba(104, 247, 170, 0.7) 100%
  );
}
.info-desc .info-desc__item_layer {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  min-height: 112px;
}
.info-desc .info-desc__item_layer:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4c32bf),
    to(#2b5145)
  );
  background: linear-gradient(90deg, #4c32bf, #2b5145);
}
.info-desc .info-desc__item_layer .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}
.info-desc .info-desc__item_layer b {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-bottom: 15px;
  color: #ffffff;
}
.info-desc .info-desc__item_layer p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}
.info-desc .info-desc__item_layer .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.info-desc .info-desc__item_layer .shadow {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.info-desc .info-desc__item_fee {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}
.info-desc .info-desc__item_fee:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4c32bf),
    to(#2b5145)
  );
  background: linear-gradient(90deg, #4c32bf, #2b5145);
}
.info-desc .info-desc__item_fee .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 32px 16px 28px;
}
.info-desc .info-desc__item_fee .bg {
  position: absolute;
  left: 50%;
  top: -94px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.info-desc .info-desc__item_fee .shadow {
  position: absolute;
  left: 50%;
  top: -30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.info-desc .info-desc__item_venom {
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-column: 2 / span 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / span 2;
}
.info-desc .info-desc__item_venom:before {
  background: #111626;
}
.info-desc .info-desc__item_venom:after {
  background: linear-gradient(-40deg, #4b33bd 20%, #2a5241 80%);
}
.info-desc .info-desc__item_venom .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 32px 16px 42px;
}
.info-desc .info-desc__item_venom p {
  margin: 0;
  font-size: 24px;
  line-height: 1;
}
.info-desc .info-desc__item_venom .logo {
  z-index: 4;
  margin: 0 0 17px;
}
.info-desc .info-desc__item_venom .bg {
  position: absolute;
  left: 50%;
  bottom: -150px;
  -webkit-transform: translateX(-56%);
  transform: translateX(-56%);
}
.info-desc .info-desc__item_venom .shadow {
  position: absolute;
  left: 50%;
  top: 115px;
  -webkit-transform: translateX(-52%);
  transform: translateX(-52%);
}
.info-desc .info-desc__item_sol {
  -ms-grid-column: 4;
  grid-column: 4;
  -ms-grid-row: 1;
  grid-row: 1;
}
.info-desc .info-desc__item_sol:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(20%, #333653),
    color-stop(80%, #2a5241)
  );
  background: linear-gradient(90deg, #333653 20%, #2a5241 80%);
}
.info-desc .info-desc__item_sol .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.info-desc .info-desc__item_sol .logo {
  z-index: 4;
}
.info-desc .info-desc__item_sol .shadow {
  position: absolute;
  left: 0;
  bottom: 0;
}
.info-desc .info-desc__item_proof {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 3;
  grid-row: 3;
  min-height: 235px;
}
.info-desc .info-desc__item_proof:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4c32bf),
    to(#2b5145)
  );
  background: linear-gradient(90deg, #4c32bf, #2b5145);
}
.info-desc .info-desc__item_proof .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px;
}
.info-desc .info-desc__item_proof .logo {
  z-index: 4;
}
.info-desc .info-desc__item_proof .bg {
  position: absolute;
  top: 24px;
  left: 48px;
  z-index: 3;
}
.info-desc .info-desc__item_proof .shadow {
  position: absolute;
  left: 50%;
  top: -30px;
  -webkit-transform: translateX(-52%);
  transform: translateX(-52%);
  z-index: 2;
}
.info-desc .info-desc__item_carbon {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 3;
  grid-row: 3;
}
.info-desc .info-desc__item_carbon:before {
  background: #161e52;
}
.info-desc .info-desc__item_carbon:after {
  background: linear-gradient(45deg, #5fc3f8, #87d289);
}
.info-desc .info-desc__item_carbon .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.info-desc .info-desc__item_rust {
  -ms-grid-column: 3;
  grid-column: 3;
  -ms-grid-row: 3;
  grid-row: 3;
}
.info-desc .info-desc__item_rust:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#17244e),
    to(#62995c)
  );
  background: linear-gradient(180deg, #17244e, #62995c);
}
.info-desc .info-desc__item_rust:after {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#83cd29),
    to(#31448f)
  );
  background: linear-gradient(0deg, #83cd29, #31448f);
}
.info-desc .info-desc__item_rust .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 0;
}
.info-desc .info-desc__item_rust .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.info-desc .info-desc__item_rust .shadow {
  position: absolute;
  left: 50%;
  top: 70px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.info-desc .info-desc__item_web {
  -ms-grid-column: 4;
  grid-column: 4;
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  grid-row: 2 / span 2;
}
.info-desc .info-desc__item_web:after {
  background: linear-gradient(45deg, #423d98, #2a5241);
}
.info-desc .info-desc__item_web .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.info-desc .info-desc__item_web .logo {
  z-index: 4;
}
.info-desc .info-desc__item_web .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-24%, -37%);
  transform: translate(-24%, -37%);
}
.info-desc .info-desc__item_web .shadow {
  position: absolute;
  left: 50%;
  top: 90px;
  -webkit-transform: translateX(-47%);
  transform: translateX(-47%);
}
.info-desc .info-desc__item_dynamical:before {
  background: #111126;
}
.info-desc .info-desc__item_dynamical:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4c32bf),
    to(#2b5146)
  );
  background: linear-gradient(90deg, #4c32bf, #2b5146);
}
.info-desc .info-desc__item_dynamical .info-desc__wrap {
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 19px 24px 17px;
}
.info-desc .info-desc__item_dynamical .logo {
  z-index: 4;
  margin-right: 20px;
}
.info-desc .info-desc__item_dynamical p {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  font-size: 1.4;
  letter-spacing: 0.02em;
}
.info-desc .info-desc__item_dynamical .shadow {
  position: absolute;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.info-desc .info-desc__item_max:before {
  background: #15164a;
}
.info-desc .info-desc__item_max:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#61dafb),
    to(#754fbe)
  );
  background: linear-gradient(90deg, #61dafb, #754fbe);
}
.info-desc .info-desc__item_max .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.info-desc .info-desc__item_max .logo {
  z-index: 4;
}
.info-desc .info-desc__item_max span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  margin: 4px 0 5px;
  z-index: 4;
}
.info-desc .info-desc__item_max p {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.05em;
  z-index: 4;
}
.info-desc .info-desc__item_max .shadow {
  position: absolute;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-52%);
  transform: translateX(-52%);
}
.info-desc .info-desc__item_inter:before {
  background: #131e3d;
}
.info-desc .info-desc__item_inter:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4c32bf),
    to(#1e76c0)
  );
  background: linear-gradient(90deg, #4c32bf, #1e76c0);
}
.info-desc .info-desc__item_inter .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.info-desc .info-desc__item_inter p {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  margin: 0;
  z-index: 4;
}
.info-desc .info-desc__item_inter .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}
.info-desc .info-desc__item_inter .shadow {
  position: absolute;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
}
.info-desc .info-desc__item_global:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4c32bf),
    to(#1e76c0)
  );
  background: linear-gradient(90deg, #4c32bf, #1e76c0);
}
.info-desc .info-desc__item_global .info-desc__wrap {
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 45px;
}
.info-desc .info-desc__item_global p {
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  margin: 0;
  z-index: 4;
}
.info-desc .info-desc__item_global .bg {
  position: absolute;
  left: 50%;
  top: 55%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
}
.info-desc .info-desc__item_global .shadow {
  position: absolute;
  left: 50%;
  top: -70px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
}
.forms-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.forms-page h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  /* line-height: 1.5; */
  /* margin: 0; */
}
.forms-page .container {
  z-index: 1;
  position: relative;
}
.forms-page__decor {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: -1;
}
.forms-page__desc {
  text-align: center;
  margin: 0 0 28px;
  color: rgba(255, 255, 255, 0.45);
  font-weight: 300;
  /* font-size: 16px; */
}
.forms-page__info {
  text-align: center;
  max-width: 420px;
  margin: 0 auto 20px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 300;
}
.forms-page__info a {
  color: #11a97d;
  text-decoration: underline;
}
.forms-page__info a:hover {
  color: #16d79f;
}
.forms-page .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  border: none;
}
.forms-page .btn::before {
  display: none;
}
.forms-page .btn span {
  line-height: 1;
  padding: 14px 27px;
}
.forms-info {
  max-width: 500px;
  margin: 0 auto;
}
.forms-info__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
}
.forms-info__tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 56px;
  width: 50%;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.2;
  cursor: pointer;
  border-top: 3px solid transparent;
}
.forms-info__tab:hover {
  background: rgba(255, 255, 255, 0.065);
}
.forms-info__tab.active {
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
  font-weight: 500;
  border-top-color: #11a97d;
}
.forms-info__content {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 0px 0px 16px 16px;
  padding: 25px 35px;
}
.forms-info__subscribe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  padding: 28px 27px;
  margin: 30px 0;
}
.forms-info__subscribe .form-group {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 22px 0 0;
}
.forms-info__captcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}
.forms-info__plane textarea {
  resize: none;
  height: 42px;
  line-height: 1.4;
  /* Track */
  /* Handle */
  /* Handle on hover */
  scrollbar-color: #d5d5d5 transparent;
  scrollbar-width: thin;
}
.forms-info__plane textarea::-webkit-scrollbar {
  width: 4px;
}
.forms-info__plane textarea::-webkit-scrollbar-track {
  background-color: transparent;
}
.forms-info__plane textarea::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #898989;
  border-radius: 10px;
  cursor: pointer;
}
.forms-info__plane textarea::-webkit-scrollbar-thumb:hover {
  background: #d0d0d0;
}
.forms-info__text {
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 300;
  margin: 20px 0 0;
}
.forms-info__text a {
  color: #ffffff;
  font-weight: 700;
}
.forms-info__text a:hover {
  color: #11a97d;
}
.forms-info__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 22px;
}
.forms-info__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.05);
}
.forms-info__img img {
  width: 30px;
  height: 37px;
}
.forms-info__main {
  font-size: 12px;
  font-weight: 300;
}
.forms-info__main p {
  margin: 0;
}
.forms-info__main ul {
  padding-left: 17px;
  margin: 0;
}
.forms-info__main li + li {
  margin-top: 3px;
}
.count-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -3px;
}
.count-list span {
  margin: 0 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  width: calc(20% - 6px);
  height: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 14px;
}
.count-list span.active {
  background-color: #11a97d;
}
.count-list span:not(.active) {
  cursor: pointer;
}
.count-list span:not(.active):hover {
  background: rgba(255, 255, 255, 0.3);
}
.set-social {
  margin: 40px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.set-social a {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}
.set-social a img {
  width: 24px;
  height: 24px;
}
.set-social a + a {
  margin-left: 16px;
}
.set-social a:hover {
  opacity: 0.7;
}
.alert {
  padding: 12px 30px 12px 39px;
  background-color: #f4e1d9;
  border-radius: 8px;
  max-width: 275px;
}
.alert__wrap {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 11;
}
.alert__close {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.alert__close::before,
.alert__close::after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  border-radius: 3px;
  background-color: #828282;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.alert__close::after {
  -webkit-transform: translate(3px, 5px) rotate(45deg);
  transform: translate(3px, 5px) rotate(45deg);
}
.alert__close::before {
  -webkit-transform: translate(3px, 7px) rotate(-45deg);
  transform: translate(3px, 7px) rotate(-45deg);
}
.alert__close:hover::before,
.alert__close:hover::after {
  background-color: #b5b5b5;
}
.alert__header {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #eb7943;
  margin: 0 0 5px;
}
.alert__header img {
  position: absolute;
  left: -25px;
  top: 1px;
}
.alert__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.alert_error {
  background: #381a21;
  border: 1px solid #eb5054;
}
.alert_error .alert__header {
  color: #eb5054;
}
.alert_error .alert__text {
  color: #ffffff;
}
.alert_success {
  background: #0f2c28;
  border: 1px solid #11a97d;
}
.alert_success .alert__header {
  color: #11a97d;
}
.alert_success .alert__text {
  color: #ffffff;
}
.alert_success-custom {
  background: #daebda;
}
.alert_success-custom .alert__header {
  color: #4ab44a;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1156px;
  }
  .main-block_custom .main-block__loop svg {
    height: 120vh;
  }
  .main-block_custom .main-block__loop img {
    height: 105vh;
  }
  .ecosystem-apps__desktop + .ecosystem-apps__main {
    width: 55%;
  }
}
@media (max-width: 1199px) {
  .init-steps__list {
    margin: 0 -15px;
  }
  .init-steps__item {
    width: calc(100% / 3 - 30px);
    margin: 0 15px;
    padding: 30px 25px;
  }
  .ecosystem__list {
    margin: 0 -15px;
  }
  .ecosystem__item {
    width: calc(100% / 3 - 30px);
    margin: 0 15px 20px;
  }
  .build__main {
    max-width: 440px;
  }
  .main-block__card {
    -webkit-transform: translate(22%, -310px);
    transform: translate(22%, -310px);
  }
  .main-block__card img {
    width: 450px;
    height: auto;
  }
  .mobile-apps__img {
    margin-right: 70px;
  }
  .mobile-apps__main {
    padding: 0;
  }
  .mobile-apps__btns .btn {
    margin: 0 20px 30px 0;
  }
  .browser__main {
    max-width: 485px;
  }
  .browser__book {
    position: absolute;
    left: 90%;
    top: 17%;
    width: 650px;
  }
  .ecosystem-apps__main {
    width: 54%;
  }
  .ecosystem-apps__item {
    padding-top: 30px;
  }
  .ecosystem-apps__mobile {
    left: 20px;
    width: 160px;
  }
  .ecosystem-apps__desktop {
    width: 175px;
  }
  .blockchain__nav {
    margin: 0 -10px;
  }
  .blockchain__item {
    width: calc(20% - 20px);
    height: 136px;
    margin: 0 10px;
  }
}
@media (max-width: 991px) {
  .header {
    height: auto;
    padding: 25px 0;
  }
  .header__nav {
    display: none;
  }
  .header__sandwich {
    display: block;
  }
  .menu-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-block {
    height: calc(100vh - 74px);
    min-height: 600px;
  }
  .main-block__card {
    -webkit-transform: translate(22%, -270px);
    transform: translate(22%, -270px);
  }
  .main-block__card img {
    width: 350px;
  }
  .detail-line {
    margin-top: 60px;
  }
  .detail-line__wrap {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    overflow: auto;
    padding: 15px 0;
    scrollbar-width: none;
  }
  .detail-line__wrap::-webkit-scrollbar {
    display: none;
  }
  .detail-line__item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: auto;
    padding: 5px 20px;
  }
  .detail-line__item b {
    margin: 0 0 10px;
  }
  .detail-line__item p {
    margin: 0;
  }
  .detail-line__item:nth-of-type(2) {
    padding: 5px 20px;
  }
  .detail-line__item + .detail-line__item {
    border: none;
  }
  .init-steps {
    padding: 80px 0 20px;
  }
  .init-steps h2 {
    margin: 0 0 80px;
  }
  .init-steps h3 {
    max-width: initial;
    margin: 0 0 20px;
  }
  .init-steps p {
    margin: 0 0 40px;
  }
  .init-steps__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
  }
  .init-steps__item {
    width: 100%;
    margin: 0 0 30px;
  }
  .init-steps__img {
    height: 180px;
  }
  .ecosystem h4 {
    font-size: 20px;
  }
  .ecosystem__main {
    padding: 140px 0;
  }
  .ecosystem__list {
    margin: 0 -15px;
  }
  .ecosystem__item {
    width: calc(50% - 30px);
    margin: 0 15px 20px;
  }
  .what-block {
    padding: 140px 0 0;
  }
  .what-block h2 {
    margin: 0 0 100px;
  }
  .what-block__main {
    padding-top: 0px;
  }
  .what-block__main p {
    font-size: 20px;
    margin: 0 0 30px;
  }
  .what-block__main .btn {
    margin: 30px 0 0;
  }
  .what-block__side svg {
    width: 292px;
    height: auto;
    position: relative;
  }
  .what-block__bg svg {
    width: 1178px;
    height: auto;
  }
  .build {
    padding: 100px 0 80px;
  }
  .build h2 {
    margin: 0 0 80px;
  }
  .build__main {
    max-width: 340px;
  }
  .build__main p {
    font-size: 20px;
  }
  .build__main .btn {
    margin: 30px 0 0;
  }
  .mobile-apps {
    padding: 120px 0;
  }
  .mobile-apps__img {
    width: 290px;
    margin-right: 60px;
  }
  .mobile-apps__main {
    padding: 0;
  }
  .mobile-apps__btns {
    margin: 50px 0 0;
    display: block;
  }
  .mobile-apps__btns .btn {
    width: 100%;
    margin: 0 0 20px;
  }
  .browser__main {
    max-width: 485px;
  }
  .browser__book {
    position: absolute;
    left: 64%;
    top: 58%;
    width: 450px;
  }
  .eco-card {
    padding: 30px 20px;
  }
  .ecosystem-apps h3 {
    margin: 0 0 30px;
  }
  .ecosystem-apps__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
  }
  .ecosystem-apps__item {
    width: 100%;
    padding-top: 30px;
  }
  .ecosystem-apps__item + .ecosystem-apps__item {
    margin: 25px 0 0;
  }
  .ecosystem-apps__mobile {
    left: 20px;
    width: 160px;
  }
  .ecosystem-apps__desktop {
    width: 138px;
  }
  .blockchain__nav {
    overflow: auto;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 -17px;
    padding: 0 17px;
    scrollbar-width: none;
  }
  .blockchain__nav::-webkit-scrollbar {
    display: none;
  }
  .blockchain__item {
    overflow: hidden;
    border-radius: 16px;
    width: 148px;
    height: 130px;
    padding: 20px 10px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 10px 0 0;
  }
  .blockchain__plane p {
    font-size: 20px;
  }
  .develop h3 {
    margin: 0 0 45px;
  }
  .develop__list {
    overflow: auto;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 -17px;
    padding: 15px 17px 0;
    scrollbar-width: none;
  }
  .develop__list::-webkit-scrollbar {
    display: none;
  }
  .develop__item {
    width: auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .develop__item p {
    padding-right: 30px;
  }
  .develop__item:last-of-type {
    width: auto;
  }
  .develop__item:last-of-type p {
    padding-right: 0;
  }
  .develop__img {
    height: 88px;
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
  .develop__img img {
    height: 100%;
    width: auto;
  }
  .develop p {
    margin: 40px 0 0;
    padding-top: 28px;
    border-top: 1px solid #374469;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .info-desc {
    -ms-grid-columns: calc(100% / 3 - 16px) 16px calc(100% / 3 - 16px) 16px
      calc(100% / 3 - 16px);
    grid-template-columns: calc(100% / 3 - 16px) calc(100% / 3 - 16px) calc(
        100% / 3 - 16px
      );
    -ms-grid-rows: auto 16px auto 16px auto;
    grid-template-rows: repeat(3, auto);
    margin: 0 0 120px;
  }
  .info-desc > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .info-desc > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .info-desc > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }
  .info-desc > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .info-desc > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .info-desc > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }
  .info-desc > *:nth-child(7) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  .info-desc > *:nth-child(8) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  .info-desc > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
  }
  .info-desc .info-desc__item_layer {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .info-desc .info-desc__item_fee {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }
  .info-desc .info-desc__item_fee .info-desc__wrap {
    padding: 20px 10px;
  }
  .info-desc .info-desc__item_fee .logo {
    width: 100%;
  }
  .info-desc .info-desc__item_venom {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2 / span 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / span 2;
  }
  .info-desc .info-desc__item_sol {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
    -ms-grid-row: 3;
    grid-row: 3;
    min-height: 140px;
  }
  .info-desc .info-desc__item_proof {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
    -ms-grid-row: 4;
    grid-row: 4;
    min-height: 140px;
  }
  .info-desc .info-desc__item_web {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    grid-row: 3 / span 2;
  }
  .info-desc .info-desc__item_carbon {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 5;
    grid-row: 5;
  }
  .info-desc .info-desc__item_carbon .info-desc__wrap {
    padding: 15px 10px;
  }
  .info-desc .info-desc__item_carbon img {
    width: 80%;
  }
  .info-desc .info-desc__item_rust {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 5;
    grid-row: 5;
  }
  .info-desc .info-desc__item_rust .logo {
    width: 70%;
  }
  .info-desc .info-desc__item_dynamical .info-desc__wrap {
    padding: 15px;
  }
  .info-desc .info-desc__item_dynamical .logo {
    width: 48px;
    margin-right: 15px;
  }
  .info-desc .info-desc__item_dynamical p {
    font-size: 18px;
  }
  .info-desc .info-desc__item_max .info-desc__wrap {
    padding: 15px;
  }
  .info-desc .info-desc__item_inter .info-desc__wrap {
    padding: 15px;
  }
  .info-desc .info-desc__item_global .info-desc__wrap {
    padding: 10px 10px 40px;
  }
  .info-desc .info-desc__item_global .bg {
    top: 65%;
  }
}
@media (max-width: 767px) {
  .menu-mobile__close {
    right: calc(50% - 255px);
  }
  .footer {
    padding: 50px 0 30px;
  }
  .footer__decor {
    left: 120%;
  }
  .main-block {
    padding: 50px 0 0;
    height: auto;
    min-height: 500px;
  }
  .main-block .container {
    padding-top: 0;
  }
  .main-block__btns {
    margin-top: 40px;
  }
  .main-block__loop video {
    height: 370px;
    min-height: 370px;
  }
  .main-block__card {
    position: relative;
    left: initial;
    top: initial;
    -webkit-transform: none;
    transform: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-block__card img {
    width: 280px;
    margin: -20px 10% 30px auto;
  }
  .detail-line__item b {
    font-size: 28px;
    margin: 0 0 5px;
  }
  .init-steps {
    padding: 60px 0 20px;
  }
  .init-steps h2 {
    margin: 0 0 60px;
  }
  .init-steps h3 {
    font-size: 22px;
  }
  .init-steps p {
    font-size: 16px;
    margin: 0 0 30px;
  }
  .ecosystem h2 {
    line-height: 1.3;
    max-width: 300px;
    margin: 0 0 30px;
  }
  .ecosystem h4 {
    font-size: 16px;
  }
  .ecosystem .btn-primary {
    width: 100%;
  }
  .ecosystem__main {
    text-align: left;
    padding: 120px 0 100px;
  }
  .ecosystem__list {
    margin: 0;
  }
  .ecosystem__item {
    width: 100%;
    margin: 0 0 15px;
  }
  .ecosystem__decor {
    left: 0;
    top: 50%;
    -webkit-transform: translate(-140px, -52%) scale(0.8);
    transform: translate(-140px, -52%) scale(0.8);
  }
  .what-block {
    padding: 100px 0 0;
  }
  .what-block h2 {
    margin: 0 0 40px;
  }
  .what-block__main {
    padding-top: 0px;
  }
  .what-block__main p {
    font-size: 18px;
    margin: 0 0 20px;
  }
  .what-block__main .btn {
    margin: 20px 0 0;
  }
  .what-block__side {
    display: none;
  }
  .what-block__bg {
    top: 300px;
  }
  .what-block__bg svg {
    width: 1178px;
    height: auto;
  }
  .what-block__list {
    margin: 80px 0 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .what-block__item {
    width: 100%;
    margin: 0 0 15px;
    padding: 30px 20px 20px;
  }
  .what-block__item .btn {
    width: 100%;
  }
  .what-block__item h3 {
    margin: 0 0 20px;
  }
  .what-block__item p {
    font-size: 16px;
  }
  .what-block__img {
    height: 80px;
  }
  .build {
    padding: 80px 0 40px;
  }
  .build h2 {
    margin: 0 0 50px;
  }
  .build__main {
    max-width: initial;
  }
  .build__main p {
    font-size: 18px;
    margin: 0 0 20px;
  }
  .build__main .btn {
    margin: 30px 0 0;
  }
  .build__side {
    margin: 0;
  }
  .build__side > img {
    display: none;
  }
  .mobile-apps {
    padding: 80px 0;
  }
  .mobile-apps__wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .mobile-apps__img {
    width: 225px;
    margin-right: 40px;
  }
  .mobile-apps__main {
    padding: 0;
  }
  .mobile-apps h2 {
    margin: 0 0 30px;
  }
  .mobile-apps p {
    font-size: 16px;
  }
  .mobile-apps__btns {
    margin: 35px 0 0;
  }
  .mobile-apps__btns .btn {
    margin: 0 0 15px;
  }
  .browser {
    padding: 30px 0 100px;
  }
  .browser__main {
    max-width: initial;
  }
  .browser h2 {
    margin: 0 0 30px;
  }
  .browser p {
    font-size: 16px;
  }
  .browser__book {
    position: relative;
    left: initial;
    top: initial;
    width: 100%;
    margin: 0 0 40px;
  }
  .blockchain > h3 {
    margin: 0 0 30px;
  }
  .blockchain__content {
    margin: 28px 0 0;
    padding-top: 25px;
  }
  .blockchain__plane p {
    font-size: 16px;
    max-width: initial;
    margin: 0;
    position: relative;
    z-index: 1;
  }
  .blockchain__plane-wrap {
    display: block;
  }
  .blockchain__plane .btn {
    width: 100%;
    margin-top: 20px;
  }
  .blockchain__plane-decor {
    position: absolute;
    right: 0%;
    bottom: -130px;
  }
  .explore-ecosystem {
    padding: 60px 0 50px;
    position: relative;
  }
  .explore-ecosystem__ex-decor {
    top: -170px;
    -webkit-transform: translateX(-70%);
    transform: translateX(-70%);
  }
  .info-desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 0 70px;
  }
  .info-desc__item {
    min-height: 120px;
  }
  .info-desc__item .info-desc__wrap {
    height: initial;
  }
  .info-desc .info-desc__item_venom {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .info-desc .info-desc__item_venom .info-desc__wrap {
    padding: 30px 5px 35px;
  }
  .info-desc .info-desc__item_venom p {
    font-size: 18px;
  }
  .info-desc .info-desc__item_layer .bg {
    width: 110%;
  }
  .info-desc .info-desc__item_fee .bg {
    width: 100%;
  }
  .info-desc .info-desc__item_fee .logo {
    width: 100%;
    max-width: 320px;
  }
  .info-desc .info-desc__item_sol {
    min-height: 120px;
  }
  .info-desc .info-desc__item_proof {
    min-height: 120px;
  }
  .info-desc .info-desc__item_carbon .info-desc__wrap {
    padding: 20px 15px;
  }
  .info-desc .info-desc__item_carbon img {
    width: 100%;
    max-width: 220px;
  }
  .info-desc .info-desc__item_rust .logo {
    width: 180px;
  }
  .info-desc .info-desc__item_web .info-desc__wrap {
    padding: 10px 15px 30px;
  }
  .info-desc .info-desc__item_web .logo {
    width: 200px;
  }
  .forms-page h1 {
    font-size: 30px;
    margin: 0 0 5px;
  }
  .forms-info__tab {
    font-size: 20px;
  }
  .forms-info__content {
    padding: 20px 15px;
  }
}
@media (max-width: 575px) {
  .menu-mobile__close {
    right: 15px;
  }
  .footer__social {
    margin: 35px 0 0;
  }
  .footer__social-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer__social-wrap a {
    margin: 0 30px 15px 0;
  }
  .footer__social-wrap a + a {
    margin: 0 30px 15px 0;
  }
  .main-block {
    padding: 50px 0 0px;
    min-height: 400px;
  }
  .main-block__btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 40px;
  }
  .main-block__btns .btn + .btn {
    margin: 15px 0 0;
  }
  .main-block__card img {
    width: 280px;
    margin: -20px 20px 30px auto;
  }
  .ecosystem__main {
    padding: 80px 0 60px;
  }
  .ecosystem__decor {
    left: 0;
    top: 50%;
    -webkit-transform: translate(-230px, -52%) scale(0.8);
    transform: translate(-230px, -52%) scale(0.8);
  }
  .ecosystem .btn.btn-custom span {
    padding: 20px 50px 20px 15px;
  }
  .ecosystem .btn.btn-custom .btn-custom__arrow {
    width: 35px;
    height: 36px;
    right: 15px;
  }
  .ecosystem .btn.btn-custom .btn-custom__arrow img {
    width: 35px;
    height: auto;
  }
  .what-block {
    padding: 80px 0 0;
  }
  .what-block__main p {
    font-size: 16px;
  }
  .what-block__main .btn {
    width: 100%;
  }
  .build {
    padding: 60px 0 40px;
  }
  .build h2 {
    margin: 0 0 40px;
  }
  .build__main p {
    font-size: 16px;
    margin: 0 0 20px;
  }
  .build__main .btn {
    width: 100%;
  }
  .mobile-apps {
    padding: 60px 0;
  }
  .mobile-apps__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .mobile-apps__img {
    display: none;
  }
  .mobile-apps__main {
    padding: 0;
  }
  .mobile-apps__main > img {
    display: block;
    width: 100%;
    max-width: 290px;
    margin: 30px auto 20px;
  }
  .mobile-apps h2 {
    margin: 0 0 30px;
  }
  .mobile-apps p {
    font-size: 16px;
  }
  .mobile-apps__btns {
    margin: 35px 0 0;
  }
  .mobile-apps__btns .btn {
    margin: 0 0 15px;
  }
  .browser {
    padding: 0 0 50px;
  }
  .browser .btn {
    width: 100%;
  }
  .browser__app {
    margin: 0 0 40px;
  }
  .ecosystem-apps h4 {
    margin: 0 0 20px;
  }
  .ecosystem-apps__item {
    width: 100%;
    padding-top: 20px;
  }
  .ecosystem-apps__item + .ecosystem-apps__item {
    margin: 25px 0 0;
  }
  .ecosystem-apps__main {
    width: 100%;
  }
  .ecosystem-apps__mobile,
  .ecosystem-apps__desktop {
    display: none;
  }
  .forms-info__subscribe {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 15px;
  }
  .forms-info__subscribe .form-group {
    width: 100%;
    margin: 0 0 15px;
  }
  .forms-info__subscribe .btn {
    width: 100%;
  }
  .set-social a + a {
    margin-left: 12px;
  }
}
