.adaptive-switch {
  min-height: calc(100vh - 150px);
}

.adaptive-switch.spases {
  margin: 0 !important;
  padding: 0 !important;
}

.adaptive-switch .switch-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.githubButton {
  margin: 25% auto 0;
  width: 100%;
  max-width: 400px;
}

.adaptive-switch .forms-page h1 {
  line-height: 1;
  margin: 40px 0 12px;
}

.box:not(.adaptive-switch) .forms-page .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.adaptive-switch.spases .forms-page {
  line-height: 1;
  flex: none;
}

.adaptive-switch.spases .forms-page h1 {
  margin-bottom: 8px;
}

@media (max-width: 991px) {
  .adaptive-switch .forms-page h1,
  .adaptive-switch.spases .forms-page h1 {
    margin-top: 21px !important;
  }
}

.adaptive-switch.spases .forms-page__desc {
  margin-bottom: 20px; /* 28px */
}

.adaptive-switch.spases .forms-info__content .form-group {
  margin-bottom: 20px; /* 30px */
}

.adaptive-switch.spases .forms-info__captcha {
  margin-bottom: 20px; /* 30px */
}

.forms-info__subscribe {
  margin-bottom: 0 !important;
}

.adaptive-switch.spases .forms-info__subscribe {
  margin-top: 20px; /* 30px */
  padding-top: 23px; /* 28px */
  padding-bottom: 23px; /* 28px */
}

.adaptive-switch.spases .set-social {
  margin-top: 15px; /* 40px */
  margin-bottom: 15px; /* 40px */
}

.loginPage {
  font-size: 14px;
  max-width: 500px;
  margin: 10% auto 0;
}
.loginBtn {
  width: 100%;
  margin-top: 30px;
}
.loginSpan:hover {
  background: linear-gradient(120deg, #11a97d 10%, #552ed3 100%) !important;
}

.loginBtn img {
  margin-right: 20px;
}

.numbered-list {
  list-style-type: none;
  counter-reset: my-counter;
  padding: 0;
}

.numbered-list li {
  counter-increment: my-counter;
  margin-bottom: 10px;
  color: rgbA(255, 255, 255, 0.45);
  /* padding: 10px; */
  position: relative;
}

.numbered-list li:before {
  content: counter(my-counter);
  color: white;

  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #11a97d;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  margin-right: 10px;
  /* font-weight: bold; */
}

.numbered-list .first:before {
  background-color: #11a97d;
  /* color: #fff; */
}
.layout-container {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  z-index: 1;
}
.maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.maintenance--main-img {
  margin-top: 120px;
}
.maintenance--title {
  font-size: 44px;
  font-weight: bold;
  margin-top: 40px;
}
